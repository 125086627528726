<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div>
      <!-- <el-button @click="addList()">添加卡类型</el-button> -->
      <excel-import @getResult="getData"></excel-import>
      <el-form v-model="form.get_code_user_id" class="aa1">
        <div style="width: 50%">
          <el-form-item label="用户手机号">
            <ul
              class="infinite-list box-style"
              v-infinite-scroll="load"
              style="overflow: auto"
            >
              <li
                v-for="i in user_name_list"
                class="infinite-list-item"
                :key="i.user_id"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span> {{ i.nick_name }}</span>
                <i
                  class="el-icon-close"
                  style="color: red; font-size: 2rem"
                  @click="deleteUser(i)"
                ></i>
              </li>
            </ul>
            <el-select
              v-model="selected_user"
              placeholder="请选择"
              value-key="user_id"
              @change="getUserIds"
              filterable
              style="margin-top: 10px"
              remote
              :remote-method="getUserList"
            >
              <el-option
                v-for="item in user_list"
                :key="item.user_id"
                :label="item.nick_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="width: 50%">
          <el-form-item label="卡券 ID ">
            <el-select v-model="form.id" placeholder="请选择券" remote>
              <el-option
                v-for="(item, couponIndex) in coupon_list"
                :key="couponIndex"
                :label="item.id"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="卡券名">
            <el-select v-model="form.id" placeholder="请选择券" remote>
              <el-option
                v-for="(item, couponIndex) in coupon_list"
                :key="couponIndex"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发卡方">
            <el-radio v-model="channels" label="合作商">合作商</el-radio>
            <el-radio v-model="channels" label="平台">平台</el-radio>
            <!-- <el-radio v-model="channels" label="商户">商户</el-radio> -->
          </el-form-item>

          <el-form-item label="有效期">
            <el-input v-model="form.duration"></el-input>月
          </el-form-item>
          <el-form-item>
            <el-button type="danger" @click="deleteItem(form)">删除</el-button>
          </el-form-item>
        </div>
      </el-form>
      <div style="text-align: right">
        <el-button @click="upload()" type="warning">生成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import excelImport from "../../components/excelImport.vue";
export default {
  components: { excelImport },
  data() {
    return {
      load: false,
      nav: { firstNav: "优惠券管理中心", secondNav: "合作商优惠券管理" },
      index: 1,
      formList: [],
      type: "",
      form: { id: "", get_code_user_id: "", duration: "" },
      channels: null,
      coupon_list: [],
      phone: "",
      mobile_list: [],
      user_name_list: [],
      user_list: [],
      selected_user: null,
      selected_user_ids: [],
    };
  },
  mounted() {
    this.getCoupon();
  },
  methods: {
    deleteItem() {
      this.form = { id: "", get_code_user_id: "", duration: "" };
    },
    getUserList(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: query,
            },
          })
          .then((res) => {
            this.user_list = res.data.data.rows;
          });
      }
    },
    addList() {
      let index = this.formList.length;
      this.$set(this.formList, index, {
        bundle_id: this.index - 1,
        user_id: JSON.parse(localStorage.getItem("managerInfo")).user_id,
      });
      this.$set(this.mobile_list, index, []);
      console.log(this.formList);
      this.index++;
      return index;
    },

    deleteUser(user) {
      this.selected_user_ids.splice(
        this.selected_user_ids.findIndex((item) => {
          return item == user.user_id;
        }),
        1
      );
      this.user_name_list.splice(
        this.user_name_list.findIndex((i) => {
          return i.user_id == user.user_id;
        }),
        1
      );
    },
    getUserIds(v) {
      if (
        this.selected_user_ids.findIndex((item) => {
          return item == v.user_id;
        }) == -1
      ) {
        this.selected_user_ids.push(v.user_id);
        this.user_name_list.push(v);
      }
    },
    getCoupon() {
      let url = "/user/commoditycoupon/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            categories: "体育运动",

            pageSize: 20,
          }
        })
        .then((res) => {
          this.coupon_list = res.data.data.rows;
        });
    },
    addToList(phone, index) {
      this.mobile_list[index].unshift(phone);
      this.phone = "";
    },
    upload() {
      let url = "/user/commoditycoupon/insertCouponListSpecial";
      this.form.get_code_user_id = this.selected_user_ids.join(",");
      this.form.user_id = JSON.parse(
        localStorage.getItem("managerInfo")
      ).user_id;
      this.form.number = this.selected_user_ids.length;
      this.form.channels = this.channels;
      this.form.duration = this.form.duration * 24 * 60 * 30;
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    // upload() {
    //   let url = '/user/commoditycoupon/insertCouponListSpecial'
    //   let promiseList = []
    //   for (let i = 0; i < this.formList.length; i++) {
    //     this.formList[i].mobiles = this.mobile_list[i].join(',')
    //     this.formList[i].number = this.mobile_list[i].length
    //     let data = { ...this.formList[i] }
    //     delete data['bundle_id']
    //     let a = this.$axios.post(url, data)
    //     promiseList.push(a)
    //   }
    //   let b = Promise.allSettled(promiseList).then((res) => {
    //     for (let i = 0; i < res.length; i++) {
    //       if (res[i].value.data.code == 0) {
    //         if (res[i].value.data.data == []) {
    //           this.$message({
    //             type: 'warning',
    //             message: '部分用户重复领取!',
    //           })
    //         } else {
    //           this.$message({
    //             type: 'success',
    //             message: '卡类型' + i + '添加成功!',
    //           })
    //         }
    //       } else {
    //         this.$message({
    //           type: 'error',
    //           message: '卡类型' + (i + 1) + '添加失败!' + res[i].value.data.message,
    //         })
    //       }
    //     }
    //     this.formList = []
    //   })
    // },
    getData(v) {
      // let index = this.addList()
      let mobile = {};
      for (let i = 0; i < v.length; i++) {
        if (mobile[v[i]["卡类型id"]]) {
          mobile[v[i]["卡类型id"]].push(v[i]["手机号"]);
          mobile[v[i]["卡类型id"]].id = v[i]["卡类型id"];
        } else {
          mobile[v[i]["卡类型id"]] = [];
          mobile[v[i]["卡类型id"]].push(v[i]["手机号"]);
        }
      }
      for (let key in mobile) {
        let index = this.addList();
        console.log(index);
        this.formList[index].mobiles = mobile[key].join(",");
        this.$set(this.formList[index], "id", mobile[key].id);
        this.mobile_list[index] = mobile[key];
        this.formList[index].number = mobile[key].length;
      }
      console.log(this.formList);
    },
  },
};
</script>

<style lang="less">
.aa1 {
  display: flex;
  @media screen and(max-width: 768px) {
    display: block;
  }
}
.box-style {
  border: 1px solid #cccccc;
  box-shadow: 5 5 5;
  background-color: rgba(200, 200, 200, 0.2);
}
.bundle-box {
  box-shadow: 2px 2px 2px #ccc;
  margin-bottom: 2rem;
  border: 1px solid rgba(200, 200, 200, 0.5);
  padding: 1rem;
}
</style>
