<template>
  <span style="margin-right: 10px">
    <input
      class="input-file"
      type="file"
      @change="exportData"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
    <el-button
      type="primary"
      icon="el-icon-upload"
      @click="btnClick"
      >上传Excel表格数据</el-button
    >
  </span>
</template>

<script>
// 引入组件
import XLSX from "xlsx";
FileReader.prototype.readAsBinaryString = function (f, _this) {
  var binary = "";
  var wb; // 读取完成的数据
  var outdata; // 你需要的数据
  var reader = new FileReader();
  reader.onload = function () {
    // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
    var bytes = new Uint8Array(reader.result);
    var length = bytes.byteLength;
    for (var i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    // 接下来就是xlsx了，具体可看api
    wb = XLSX.read(binary, {
      type: "binary",
    });
    outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    // 自定义方法向父组件传递数据
    _this.$emit("getResult", outdata);
  };
  reader.readAsArrayBuffer(f);
};
export default {
  props: ["clear"],
  data() {
    return {
      files: [],
    };
  },
  methods: {
    btnClick() {
      // 点击事件
      document.querySelector(".input-file").click();
    },
    exportData(event) {
      console.log(event);
      this.files = event.currentTarget.files;
      if (!event.currentTarget.files.length) {
        return;
      }
      const that = this;
      // 拿取文件对象
      var f = that.files[0];
      console.log(f);
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      reader.readAsBinaryString(f, that);
      document.querySelector(".input-file").value = ''
    },
  },
  watch: {},
};
</script>

<style>
.input-file {
  display: none;
}
</style>
